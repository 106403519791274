import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ApiService, APIMethod } from '../../shared/services/api.service';
import { APIRequest } from '../../shared/models/apirequest.model';
import { catchError, map, retry } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export class AuthService {
  @BlockUI() public blockUI!: NgBlockUI;
  isUserAuthenticated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private apiservice: ApiService, private http: HttpClient) {}

  getApiToken(OKTAToken: any): Observable<any> {
    const apiRequest: APIRequest = new APIRequest(
      `Authentication/auth?OktaAccessToken=${OKTAToken}`,
      APIMethod.POST
    );
    //apiRequest.addAll(OKTAToken);
    return this.apiservice.executeAPI(apiRequest).pipe(
      map((result) => {
        this.blockUI.stop();
        this.isUserAuthenticated.emit(true);
        return result.token;
      })
    );
  }

  getRefreshToken(refreshTokenKey: any): Observable<any> {
    const apiRequest: APIRequest = new APIRequest(
      `Authentication/refresh?refreshToken=${refreshTokenKey}`,
      APIMethod.POST
    );
    //apiRequest.addAll(refreshTokenKey);
    return this.apiservice.executeAPI(apiRequest).pipe(
      map((result) => {
        return result.token;
      })
    );
  }

  gettoken(){  
    return !!localStorage.getItem("SeesionUser");  
    } 
}
