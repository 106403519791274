import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderOptions } from '../models/HeaderOptions';
import { APIRequest } from '../../shared/models/apirequest.model';
import { APIMethod, ApiService} from '../../shared/services/api.service';
import { RecentMessage, UserNotificationCount } from '../models/user-notification.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient,
    private apiservice: ApiService) 
    { 

    }

  getOptions()
  {
    return this.http.get<HeaderOptions[]>("/assets/Header/options.json");
  }

  getMenuListByUserEmail(emailAddress:string): Observable<any> {
    const apiRequest: APIRequest = new APIRequest('User/userrolesandpermissions?email='+emailAddress, APIMethod.GET);
    return this.apiservice.executeAPI(apiRequest).pipe(
      map((result) => {
       
        return this.parseData(result);
      })
    );
  }

  parseData(response: any) {
    if(response['IsSuccess']===true){
    const parseResponse = response['Result'];
    return parseResponse;
    }
  }


  getNotificationCount(): Observable<UserNotificationCount> {
    // let requestUrl =
    //   `${this._baseUrl}messages/` + localStorage.getItem('user_id') + '/count';
    // return this._httpClient.get<UserNotificationCount>(requestUrl);


    const apiRequest: APIRequest = new APIRequest('Notification/messages/'+localStorage.getItem('user_id')+ '/count' , APIMethod.GET);
    return this.apiservice.executeAPI(apiRequest).pipe(
      map((result) => {
        return this.parseData(result);
      })
    );
  }
  getUserNotification(): Observable<RecentMessage[]> 
    {
    // let requestUrl =
    //   `${this._baseUrl}messages/` + localStorage.getItem('user_id') + '/unread';
    // return this._httpClient.get<RecentMessage[]>(requestUrl);


    const apiRequest: APIRequest = new APIRequest('Notification/messages/'+localStorage.getItem('user_id')+ '/unread' , APIMethod.GET);
    return this.apiservice.executeAPI(apiRequest).pipe(
      map((result) => {
        return this.parseData(result);
      })
    );
  }
  markasread(messageId: string): Observable<any> { 
      const apiRequest: APIRequest = new APIRequest('Notification/messages/'+
      localStorage.getItem('user_id') +
      '/' +
      messageId, APIMethod.PUT);
      apiRequest.addAll({});
      return this.apiservice.executeAPI(apiRequest).pipe(
        map((result) => {
          return result;
        })
      );
    
  }

  showLoader(){
    this.apiservice.blockUI.start();
  }

}
