import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent,OktaAuthGuard } from '@okta/okta-angular';
import { AuthGuard } from './auth-guard';
const CALLBACK_PATH = 'login/callback';
const routes: Routes = [
  {
    path: CALLBACK_PATH,
    component: OktaCallbackComponent,
  },    
  {
    path: 'privacypolicy',
    loadChildren: () => import('./privacypolicy/privacypolicy.module').then((m) => m.PrivacypolicyModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./logged-in-auth/logged-in-auth.module').then((m) => m.LoggedInAuthModule),
    canActivate: [ OktaAuthGuard]
  },
  {
    path: 'managegroupreports',
    loadChildren: () => import('./groupreportsettings/groupreportsettings.module').then((m) => m.GroupreportsettingsModule),
    canActivate :[AuthGuard]
  },
  {
    path: 'enquiry',
    loadChildren: () => import('./enquiry/enquiry.module').then((m) => m.EnquiryModule),
  },
  {
    path: 'securitypolicy',
    loadChildren: () => import('./securitypolicy/securitypolicy.module').then((m) => m.SecuritypolicyyModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      canActivate: [ AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
      canActivate :[AuthGuard]
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./company/company.module').then((m) => m.CompanyModule),
      canActivate :[AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./report/report.module').then((m) => m.ReportModule),
      canActivate :[AuthGuard]
  },
  {
    path: 'manageuserreport',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
      canActivate :[AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'testimonial',
    loadChildren: () => import('./testimonial/testimonial.module').then((m) => m.TestimonialModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contactus/contactus.module').then((m) => m.ContactUsModule),
  },
  {
    path: 'blogs',
    loadChildren: () => import('./blogs/blogs.module').then((m) => m.BlogsModule),
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then((m) => m.AboutusModule),
  },
  {
    path: 'termsandcondition',
    loadChildren: () => import('./termsandcondition/termsandcondition.module').then((m) => m.TermsandconditionModule),
  },

  {
    path: 'uploadfiles',
     loadChildren: () => import('./uploadfiles/uploadfiles.module').then((m) => m.UploadfilesModule),
     canActivate :[AuthGuard]
  },
  {
    path: 'userenquiry',
    loadChildren: () => import('./userenquiry/userenquiry.module').then((m) => m.userenquiryModule),
    canActivate :[AuthGuard]
  }, 
  {
    path: 'data',
    loadChildren: () => import('./dataingest/dataingest.module').then((m) => m.DataingestModule),
    canActivate :[AuthGuard]
  }, 
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
