import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {SessionService} from './session.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { UserIdleService } from 'angular-user-idle';

/*
 * The @Injectable decorator has been applied to the AccountService class. 
 * This decorator is used to tell Angular that this class will be used as a service,  
 * by doing this other classes are allowed to access the functionality of our account service class through a feature called dependency injection. 
*/ 

@Injectable({
  providedIn: 'root'
})

 export class AccountService {

    // Need HttpClient to communicate over HTTP with Web API
    constructor(private http : HttpClient, private router : Router, private sessionservice : SessionService, private oktaAuth : OktaAuth,private userIdle:UserIdleService) { }
   
    public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isUnauthorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // User related properties
    //private loginStatus = new BehaviorSubject<boolean>(false);
    //private UserName    = new BehaviorSubject<any>(this.sessionservice.getLocalStorage('username'));
    //private UserRole    = new BehaviorSubject<any>(this.sessionservice.getLocalStorage('userRole'));
    //private UserSession    = new BehaviorSubject<any>(this.sessionservice.getLocalStorage('SessionUser'));
  
   
     logout() 
    {
      
        // Set Loginstatus to false and delete saved jwt cookie
      
        this.isUserLoggedIn.next(false);
        this.sessionservice.removeLocalStorage('SessionUser');
        this.sessionservice.removeLocalStorage('userRole');
        this.sessionservice.removeLocalStorage('username');
        this.sessionservice.removeLocalStorage('expiration');
        this.sessionservice.removeLocalStorage('Menu');
        sessionStorage.clear();
        localStorage.clear();
        this.oktaAuth.signOut();
        this.sessionservice.setLocalStorage('loginStatus', '0');
     //   this.oktaAuth.signOut().then;
      // this.oktaAuth.signOut().then(async ()=>{
      //     await this.oktaAuth.signInWithRedirect({
      //           originalUri:'/auth',
      //         });
      //         console.log('IN')
      //        });
      //   },function(rej) {
      //     //here when you reject the promise
      //       console.log(rej)
      //   });


       //this.oktaAuth.signOut({ postLogoutRedirectUri:"/?showwidget=0" })
        this.oktaAuth.tokenManager.clear();
        this.sessionservice.deleteAllCookies();  
      
        this.Idlestop();
        this.IdleStopWatching();
       
    }
    IdleStartWatching() {
      this.userIdle.startWatching();
    }
    IdleStopWatching() {
      this.userIdle.stopWatching();
    }

    Idlestop() {
      this.userIdle.stopTimer();
    }
    
    IdleRestart() {
      this.userIdle.resetTimer();
    }

}
