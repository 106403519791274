import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionService } from './shared/services/session.service';
import { AccountService } from './shared/services/account.service';
import { AuthService } from './shared/services/auth.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { Location } from '@angular/common';
import { SignalRService } from './shared/services/signalR.service';
import { UserIdleService } from 'angular-user-idle';
import { fromEvent, merge } from 'rxjs';
import { constants } from './shared/constants-enums/constants';
import { HelperService } from './shared/services/helper.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'TI-Discovery-Web';
  MVPEDIT:boolean=constants.ENABLEMVPITEMS;
  routerURL: string = '';
  isUserLoggedIn!: boolean;
  isloggedInMenuExist = this.sessionService.getLocalStorage('Menu') == null ? false : true;
  UserInActive = false;
  IsUnauthorized=false;
  timer = '';
  totalwarningtime = constants.WARNING_TIME;
  techsupportcontact=constants.TECH_SUPPORT_CONTACT;
  techsupportmail=constants.TECH_SUPPORT_MAIL;
  salessupportcontact=constants.SALES_SUPPORT_CONTACT;
  salessupportmail=constants.SALES_SUPPORT_MAIL;
  DeniedNote:string=constants.ACCESSDENIEDNOTE;


  

  constructor(private location: Location, private signalRService: SignalRService,private helperservice:HelperService, private oktaAuth: OktaAuth, private sessionService: SessionService, private router: Router, private authService: AuthService, private accountService: AccountService, private userIdle: UserIdleService) {

const wheelEvent = fromEvent(document, 'wheel');
const clickEvent = fromEvent(document, 'click');
const scrollEvent = fromEvent(document, 'scroll',{ capture: true });
const keyboardEvent = fromEvent(document, 'keydown');
//const leftclickEvent = fromEvent(document, 'auxclick');

const allEvents = merge(
     wheelEvent, clickEvent, keyboardEvent,scrollEvent
)
this.userIdle.setCustomActivityEvents(allEvents);
  }


  async ngOnInit() {
   this.helperservice.Showheader(false);
    this.accountService.isUnauthorized.subscribe(value => {
      
      this.IsUnauthorized = value;
    })
    this.userIdle.onTimerStart().subscribe(
      count => {

        if (count != null) {
          this.UserInActive = true;
          var remain = this.totalwarningtime - count;
          var remainMinutes = Math.floor(remain / 60) + ' min';
          var remainSeconds = (remain % 60 < 10 ? '0' + remain % 60 : remain % 60) + ' sec';
          this.timer = remainMinutes + ":" + remainSeconds;
        }

      }

    );

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(res => {
      this.Logout();
      this.accountService.Idlestop();
    }

     );

    try {
      let isAuthenticated = await this.oktaAuth.isAuthenticated();
      if (this.sessionService.getLocalStorage('okta-token-storage') == '{}') {
        if (isAuthenticated) {
         
         let val = this.oktaAuth.getRefreshToken();
          this.auth();
        }
        else {
          this.accountService.logout();
        }

      }
      if (this.isloggedInMenuExist) {
        let Username = this.sessionService.getClaimFromToken("Username")!;
        localStorage.setItem('user_id', Username);
        // this.signalRService.init();
        this.accountService.isUserLoggedIn.next(true);
        this.userIdle.startWatching();
      }
      this.accountService.isUserLoggedIn.subscribe(value => {
        this.isUserLoggedIn = value;
        if (this.isUserLoggedIn) {

          let loggedInMenu: any = this.sessionService.getLoggedInUserMenu();
          let loggedInsubMenu = loggedInMenu.flatMap(
            (elem: { SubMenu: any; }) => elem.SubMenu
          )
          let flattenLoggesInMenu = [].concat.apply(loggedInMenu, loggedInsubMenu);
          let checkValidRoute = flattenLoggesInMenu.filter((a: { Path: string; }) => a.Path == this.location.path());
          if (checkValidRoute.length > 0 && this.location.path() != "" && this.location.path() != "/auth" && this.router.url.includes('/')) {
            this.router.navigate([this.location.path()]);
          }
          else {
            this.router.navigate(['/auth']);

          }
        }

      });

    }
    catch (error: any) {
      if (error.errorCode == 'login_required') {
        this.accountService.logout();
        return;
      }
    }

  }

  auth() {
    this.authService
      .getApiToken(this.oktaAuth.getAccessToken())
      .subscribe((result: any) => {
        this.sessionService.setAccessToken(result);

      });
  }

  Logout() {
    this.UserInActive = false
    this.accountService.logout();
  }

  Extend() {
    this.UserInActive = false;
    this.accountService.Idlestop();
    this.accountService.IdleRestart();
  }
  onActivate() {   
     document.body.scrollTop = 0;
  
 }
  ngOnDestroy() {
    
  }
}
