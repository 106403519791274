import { Injectable } from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private confirmationService: ConfirmationService) { }

  confirmation(messageString:string,headerString:string,{
    message = messageString,
    header = headerString,
  
  } = {}): Promise<boolean> {
    return new Promise(resolve => {
        this.confirmationService.confirm({
          message,
          header,
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        })
    });
  }

  Deletion(messageString: string, headerString: string, {
    message = messageString,
    header = headerString,
    
  } = {}): Promise<boolean> {
    return new Promise(resolve => {
      this.confirmationService.confirm({
        message,
        header,
        
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      })
    });
  }
}

