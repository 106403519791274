<div>
  <block-ui [template]="blockTemplate">
    <div>
      <app-header></app-header>
    </div>
    <div style="min-height: calc(100vh - 95px);">
      <router-outlet (activate)="onActivate()"></router-outlet>
    </div>
    <div class="float">
      <p-speedDial *ngIf="MVPEDIT" direction="up" showIcon="pi pi-phone" hideIcon="pi pi-phone"
        (onClick)="op.toggle($event)">
      </p-speedDial>
    </div>
    <div class="helpsupport">
      <p-overlayPanel #op>
        <ng-template pTemplate>
          <div class="col-md-12">
            <div class="row">
              <label class="discovery-label heading">Having troubles? Feel free to contact us:</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label class="discovery-label subheading">Tech Support: </label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label class="discovery-label subpara">{{techsupportcontact}} or</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <a href="mailto:{{ techsupportmail }}" target="_blank"><label
                  class="discovery-label subpara1">{{techsupportmail}}</label></a>

            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label class=" discovery-label subheading">Sales Support: </label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <label class="discovery-label subpara">{{salessupportcontact}} or</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <a href="mailto:{{ salessupportmail }}" target="_blank"> <label
                  class="discovery-label subpara1">{{salessupportmail}}</label></a>

            </div>
          </div>

        </ng-template>
      </p-overlayPanel>
    </div>
    <div>
      <app-footer></app-footer>
    </div>
  </block-ui>
  <ng-template #blockTemplate>
    <div class="block-ui-template cssload-loader">
        <div class="loaderx">
          <svg class="circularx" viewBox="25 25 50 50">
            <circle class="pathx" cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10"/>
          </svg>
        </div>
    </div>
  </ng-template>

  <p-dialog [(visible)]="UserInActive"  [modal]="true" [closable]="false" [draggable]="false">
    <ng-template pTemplate="header">
      <h2 class="head">Session Timeout</h2>
    </ng-template>
    <div class="row mt-1">
      <div class="col-md-12">
        <div> <span class="Message"> You will be logged out of the session due to inactivity within</span>
          <div></div> <span class="beon-black-label"> {{timer}} </span>
        </div>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button (click)="Logout()" label="Logout" styleClass="p-actionlink20-btn"></p-button>
      <p-button (click)="Extend()" label="Continue" styleClass="p-primary20-btn"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="IsUnauthorized" class="beon-primary-btn" (onHide)="Logout()" [modal]="true">
    <ng-template pTemplate="header">
      <h3 class="head">Access Denied</h3>
    </ng-template>
    <div class="row mt-2">
          <div class="col-md-12 beon-black-label">
        {{DeniedNote}}
      </div>
    </div>
    <ng-template pTemplate="footer">
      <p-button (click)="Logout()" label="Ok" styleClass="p-primary20-btn"></p-button>
    </ng-template>
  </p-dialog>


</div>
