<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top" id="mainheader">
  <div class="container-fluid">
  <a class="navbar-brand" >
    <!-- <small><h1>Beon Discovery</h1></small> -->
    <!-- <div class="head-pad-right margin-left-20">
      <img src=""
    </div> -->
    <div class="logo margin-left-5">
      <img  src="assets/Header/beon-shipper-logo-white.svg" alt="main icon">
    </div>
  </a>

  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex gap-3" *ngIf="isUserLoggedIn">
      <div *ngFor="let i of menuOptions[0]?.Menu">
        <div *ngIf="i.IsShowIcon==false && i.SubMenu.length == 0">
          <li class="nav-item head-pad-right" routerLinkActive="underline" >
            <a class="nav-link weight no-space" routerLink={{i.Path}} routerLinkActive='beon-header-focused' >
              {{i.PermissionTitle | titlecase }}
            </a>
          </li>
        </div>

        <div *ngIf="i.SubMenu.length > 0">
          <li class="nav-item dropdown" [routerLinkActive]="['underline']">
            <a class="nav-link dropdown-toggle weight head-pad-right" href="#" id="navbarDarkDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive='beon-header-focused'>
              {{i.PermissionTitle | titlecase}}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
              <li *ngFor="let j of i.SubMenu">
                <a class="dropdown-item" routerLinkActive='active' routerLink={{j.Path}}>
                  {{j.PermissionTitle | titlecase}}
                </a>
              </li>
            </ul>
          </li>
        </div>

        <div *ngIf="i.IsShowIcon">
          <li class="nav-item">
            <a class="nav-link head-pad-right" routerLinkActive='active' routerLink={{i.Path}}>
              <span class="imgcontainer" pTooltip="{{i.Path | slice:1:(i.Path.length)}}" tooltipPosition="bottom"[ngClass]="{'setting':i.Path == '/manageuserreport','help':i.Path == '/help','groupreportsettings':i.Path=='/managegroupreports'}"></span>
            </a>
          </li>
        </div>
      </div>

      <li>
        <div class="profiletooltip">
        </div>
      </li>
    </ul>

    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
      <!-- bell -->
    <li class="nav-item">
      <!-- <div class="btn-group" dropdown>
        <a class="nav-link head-pad-right"  dropdownToggle >
          <span class="imgcontainer user" ></span>
        </a>

        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right info" role="menu">
          <li role="menuitem" class="iconitem">
            <p class="dropdown-item "><img height="22" width="22" src="/assets/Header/user.svg" alt="Header Icon">
             <b class="margin-left-5">{{firstlastname}}</b></p>
          </li>
          <li role="menuitem" class="menuitem">
            <p class="dropdown-item" style="color: grey;">{{username}}</p>
          </li>
          <li role="menuitem"class="menuitem">
            <p class="dropdown-item ">A/c Name: {{accountname}}</p>
          </li>
          <li role="menuitem"class="menuitem"><a class="dropdown-item" (click)="Logout()"><b style="color:#236092;">Logout</b></a>
          </li>
        </ul> -->


        <div *ngIf="MVPEDIT" class="usernotification">
          <div class="row">
              <div class="col text-right">
                  <!-- <div ngbDropdown class="d-inline-block">
                      <i class="pi pi-bell badge-wrapper" id="dropdownBasic1" style ="top: 11px;
                      right: 5px;" (click)="getUserNotification()" ngbDropdownToggle >
                          <span class='badge badge-secondary'>1</span>
                      </i>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                          <ul>
                              <li   (click)="popup('')" ngbDropdownItem>
                                 tets
                              </li>
                          </ul>
                      </div>
                  </div> -->


                  <!-- <li class="nav-item" >
                    <div class="btn-group" dropdown>

                      <a dropdownToggle class="nav-link dropdown-toggle weight head-pad-right ">
                      <i class="pi pi-bell badge-wrapper" id="dropdownBasic1" style ="top: 11px;
                      right: 5px;" (click)="getUserNotification()" dropdownToggle >
                          <span class='badge badge-secondary'>1</span>
                      </i>
                    </a>
                      <ul *dropdownMenu class="dropdown-menu">
                        <div >
                          <li role="menuitem"><a class="dropdown-item" routerLinkActive='active'
                             >zczcxvxcvc</a>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </li> -->





                  <div class="btn-group" dropdown  pTooltip="Notification" tooltipPosition="bottom"  >
                    <a class="nav-link head-pad-right"  [class.isDisabled]="UserNotificationCount==0" dropdownToggle >
                      <!-- <i class="pi pi-bell badge-wrapper" id="dropdownBasic1" style ="top: 2px;
                      right: 5px;" (click)="getUserNotification()" dropdownToggle >
                          <span class='badge badge-secondary'>1</span>
                      </i> -->

                      <!-- <i class="pi pi-bell badge-wrapper"  style ="top: 2px;" id="dropdownBasic1" (click)="getUserNotification()" dropdownToggle >
                        <span class='badge badge-secondary' style="padding: 2px" *ngIf="UserNotificationCount>0">{{UserNotificationCount}}</span>
                    </i> -->
                    <span class="imgcontainer notification" (click)="getUserNotification()">
                      <span class='badge badge-secondary' *ngIf="UserNotificationCount>0">{{UserNotificationCount}}</span>
                    </span>
                    </a>
                     <div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right info" role="menu" >
                      <li role="menuitem" *ngFor="let userNotification of UserNotification" (click)="popup(userNotification)" ><a class="dropdown-item"
                       >  {{userNotification.Subject}}</a>

                     </li>

                    </ul>
                  </div>


                  </div>




              </div>
          </div>

          <p-dialog header="Message" [(visible)]="display" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
              [resizable]="false" (onHide)="callback()">
              {{content}}
          </p-dialog>
      </div>


    </li>
    <!-- bell -->

    <li class="nav-item" *ngIf="isUserLoggedIn">
      <div class="btn-group"  dropdown>
        <a class="nav-link head-pad-right" pTooltip="Profile" tooltipPosition="bottom" dropdownToggle >
          <!-- <span class="imgcontainer user" ></span> -->
          <div class="circle">
       <span class="text-uppercase"> {{firstName | slice:0:1}}{{lastName | slice:0:1}}</span>
          </div>
        </a>

        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right info" role="menu">
          <li role="menuitem" class="iconitem isDisabled">
            <p class="dropdown-item "><img class="userimage" src="/assets/Header/user.svg" alt="Header Icon">
             <b class="margin-left-5">{{firstlastname}}</b></p>
          </li>
          <li role="menuitem" class="menuitem isDisabled">
            <p class="dropdown-item grey">{{username}}</p>
          </li>
          <li role="menuitem" class="menuitem isDisabled">
            <p class="dropdown-item ">Role : {{RoleName}}</p>
          </li>
          <li role="menuitem"class="menuitem isDisabled" *ngIf="!InternalUser">
            <p class="dropdown-item ">A/c Name : {{accountname}}</p>
          </li>

          <li role="menuitem"class="menuitem"><a class="dropdown-item" (click)="confirmlogout()"><b class="logout">Logout</b></a>
          </li>
        </ul>

      </div>
    </li>
    </ul>

    <ul class="navbar-nav ms-auto" *ngIf="!isUserLoggedIn">
      <div *ngFor="let i of offlineMenu[0]?.Menu;let idx=index">
        <div *ngIf="i.IsShowIcon==false && i.SubMenu.length == 0 && i.PermissionType!='BUTTON'">
          <li class="nav-item head-pad-right"  [ngClass]="idx==selectedIndex ? 'underline beon-header-focused':''"  routerLinkActive="underline">
            <a class="nav-link weight" routerLink={{i.Path}} routerLinkActive='beon-header-focused' (click)="login(i.Path);selectedIndex=idx">
              {{i.PermissionTitle | titlecase }}
            </a>
          </li>
          <!-- [ngClass]="idx==selectedIndex ? 'underline':''" -->
        </div>

        <div *ngIf="i.SubMenu.length > 0">
          <li class="nav-item" routerLinkActive="underline">
            <div class="btn-group" dropdown>
              <a dropdownToggle class="nav-link dropdown-toggle weight head-pad-right " routerLinkActive='active'>
                {{i.PermissionTitle | titlecase}}
              </a>
              <ul *dropdownMenu class="dropdown-menu">
                <div *ngFor="let j of i.SubMenu">

                  <li role="menuitem"><a class="dropdown-item" routerLinkActive='beon-header-focused'
                      routerLink={{j.Path}}>{{j.PermissionTitle | titlecase}}</a>
                  </li>
                </div>
              </ul>
            </div>
          </li>
        </div>

        <div *ngIf="i.PermissionType==='BUTTON'">
          <li class="head-pad-right discovery-search-btn widthcss" routerLinkActive="">
            <button pButton class="p-button-warning" routerLink={{i.Path}} routerLinkActive='beon-header-focused ' label="{{i.PermissionTitle }}">
            </button>
        </div>
      </div>
    </ul>
  </div>
  </div>
</nav>
<p-confirmDialog class="confirmpopup" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
