import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear!:number;
  footerBoldText: string ="";
  footerNormalText: string ="";
  constructor() { }

  ngOnInit(): void {
    this.footerNormalText =" All rights reserved";
    this.footerBoldText ="Transportation Insight";
    this.currentYear = new Date().getFullYear();
  }

}
