import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { HelperService } from './shared/services/helper.service';
import { SessionService } from './shared/services/session.service';
import { AuthService } from './shared/services/auth.service';
import { AccountService } from './shared/services/account.service';
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private logoutURl: string = '';
  private isRefreshing: Boolean = false;
  constructor(
    private sessionService: SessionService,
    private helperService: HelperService,
    private accountService: AccountService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = this.GetAuthHeaders();
    // Clone the request to add the new header.
    if (this.helperService.isEmpty(authHeader)) {
      return next.handle(req).pipe(tap((evt) => {}));
    } else {
      // integrate the header in the outgoinf request
      const authReq = req.clone({ headers: authHeader });

      return next.handle(authReq).pipe(
        tap((evt) => {}),
        catchError((err) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === 401 &&
            this.sessionService.checkJWTExpiry()
          ) {
            
            return this.handle401Error(req, next);
          }
          return throwError(err);
        })
      );
    }
  }

  private GetAuthHeaders(): HttpHeaders {
    ;
    let headers: HttpHeaders;
    const token = this.sessionService.getAccessToken();
    if (!this.helperService.isEmpty(token)) {
      headers = new HttpHeaders({
        Authorization: `bearer ${token}`,
      });
      return headers;
    }
    return new HttpHeaders({});
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      
      return this.authService
        .getRefreshToken(this.sessionService.getRefreshTokenKeyFromJWT())
        .pipe(
       
          switchMap((token) => {
            this.sessionService.removeAccessToken();
            this.sessionService.setAccessToken(token);
            this.isRefreshing = false;
            return next.handle(
              req.clone({
                headers: new HttpHeaders({
                  Authorization: `bearer ${token}`,
                }),
              })
            );
          }),
          catchError((err) => {
            this.sessionService.deleteAllCookies();
           // window.location.assign(this.logoutURl);
           this.accountService.logout();
            return throwError(err);
          })
        );
    }
    return next.handle(req);
  }
}
