import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { SignalRConnectionInfo } from '../models/signalr-connection-info.model';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { constants } from '../constants-enums/constants';

@Injectable()
export class SignalRService {
  private readonly _baseUrl: string = constants.AZURECONNECTION;
  private hubConnection!: HubConnection;
  messages$: Subject<string> = new Subject();

  constructor(private _http: HttpClient) {}

  //ToDo : get the constant from constant.ts , like 'noti5'

  private getConnectionInfo(): Observable<SignalRConnectionInfo> {
    let requestUrl = `${this._baseUrl}negotiate`;
    return this._http.get<SignalRConnectionInfo>(requestUrl);
  }

  init() {
    this.getConnectionInfo().subscribe((info) => {
      let options = {
        accessTokenFactory: () => info.accessToken,
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(info.url, options)
        .build();

      this.hubConnection.start().catch((err) => console.error(err.toString()));

      this.hubConnection.on('noti5', (data: any) => {
        this.messages$.next(data);
      });
    });
  }
}
