import { Injectable, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HelperService } from '../services/helper.service';
import { Location } from '@angular/common';
/**
 * Service for managing the session data for logged in user
 * [Injectable]-> Annotation to indicate that service will be injectale in application.
 */
@Injectable()
export class SessionService {
  appTokenKey = 'APITOKEN';
  constructor(
    private cookie: CookieService,
    private helperService: HelperService,
    private location: Location
  ) { }
  getAccessToken(): string | null {
    //return this.cookie.get(this.appTokenKey);
    return localStorage.getItem(this.appTokenKey);
  }
  setAccessToken(token: any) {
    //this.cookie.set(this.appTokenKey, token);
    localStorage.setItem(this.appTokenKey, token);
  }
  removeAccessToken() {
    this.cookie.delete(this.appTokenKey);
  }
  deleteAllCookies() {
    this.cookie.deleteAll();
  }

  setLocalStorage(key: string, data: any) {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(key, jsonData);
  }
  getLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }
  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }
  getRefreshTokenKeyFromJWT(): string {
    var jwtToken: any;
    if (!this.helperService.isEmpty(this.getAccessToken())) {
      jwtToken = JSON.parse(atob(this.getAccessToken()!.split('.')[1]));
    }
    return jwtToken['RefreshToken'];
  }
  getClaimFromToken(Key: string): string | null {
    var jwtToken: any;
    if (!this.helperService.isEmpty(this.getAccessToken())) {
      jwtToken = JSON.parse(atob(this.getAccessToken()!.split('.')[1]));
    }
    return (jwtToken === undefined) ? null : jwtToken[Key];
  }
  getOKTAAccessToken(): string {
    if (
      this.getLocalStorage('okta-token-storage') !== '' &&
      this.getLocalStorage('okta-token-storage') !== null
    ) {
      var OKTAStorage: any = this.getLocalStorage('okta-token-storage');
      return JSON.parse(OKTAStorage)['accessToken']['accessToken'];
    }
    return '';
  }
  checkJWTExpiry(): boolean {
    //Get the API Token From Cookies
    const token = this.getAccessToken();

    if (token !== '' && token !== undefined && token !== null) {
      //parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(token.split('.')[1]));

      //Check the JWT token Expiry
      const jwtexpires = jwtToken.exp * 1000;
      if (jwtexpires < new Date().getTime()) {
        return true;
      }
    }
    return false;
  }

  getLoggedInUserMenu(filterAction=false) {
    let menu: any = this.getLocalStorage('Menu');
    menu = JSON.parse(menu);
    return filterAction?menu[0].Action:menu[0].Menu;

  }

  getPermision(PermissionKey: any) {

    let grantedActions:any = this.getLoggedInUserMenu(true);

    let res = false;
    let filterMenu = grantedActions?.filter((a: { PermissionType: string; PermissionKey: string; IsActive: boolean }) => a.PermissionType.toLowerCase() == 'action' && a.PermissionKey.toLowerCase() == PermissionKey.toLocaleLowerCase() && a.IsActive)
    if (filterMenu!=undefined && filterMenu.length > 0) {
    //  data = filterMenu[0].SubMenu?.filter((a: { PermissionType: string; PermissionKey: string; IsActive: boolean }) => a.PermissionType.toLowerCase() == 'action' && a.PermissionKey.toLowerCase() == PermissionKey.toLocaleLowerCase() && a.IsActive)
        res = true;
    }
    return res;

  }
}
