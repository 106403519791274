import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { SortTypes } from '@app/shared/constants-enums/Enums';

@Injectable()
export class HelperService {
  constructor(
    private cookie: CookieService,
    private messageService: MessageService
  ) {}
  isEmpty(data: any): boolean {
    if (data instanceof Array) {
      return data === null || data === undefined || data.length === 0;
    } else {
      if (data) {
        data = data.toString().trim();
      }
      return data === null || data === undefined || data === '';
    }
  }

  showToaster(status: any, message: string) {
    if (!this.isEmpty(message)) {
      if (status === 'Success') {
        this.messageService.add(
        {severity:'beon-success', summary: '', detail: message, icon: 'toast-success-icon'}
        );
      } else if (status === 'Warning') {
        this.messageService.add(
        {severity:'beon-warning', summary: '', detail: message, icon: 'toast-warning-icon'}
        );

      } else if (status === 'Error') {
        this.messageService.add(
        {severity:'beon-error', summary: '', detail: message, icon: 'toast-error-icon'}

        );

      }

    }

  }
  // return the short date
  getCurrentTimeOffSetDate(date: Date): any {
    return new Date(date.getTime() + date.getTimezoneOffset() * -1 * 60000)
      .toJSON()
      .slice(0, 10);
  }

  getUserEmailAsClaims(): string | null {
    // if(!this.isEmpty(localStorage.getItem('okta-token-storage'))){
    //   const jwtToken = this.sessionService.getLocalStorage('okta-token-storage');
    //   if (jwtToken !== '' && jwtToken !== undefined && jwtToken !== null) {
    //     jwtToken = JSON.parse(jwtToken['accessToken']['accessToken']);
    //   }

    // }
    return null;
  }
  getCookieByKey(key:string){
   return this.cookie.get(key);
  }




  public uploadChunkFile(fileQueue:any,chunkSize:any,start:number,currentChunkId:number) {

    const chunk = this.splitChunk(fileQueue.file, start,chunkSize);

    if (chunk.success) {
      fileQueue.chunkQueue.push({
        index: currentChunkId,
        data: chunk.result,
        start: chunk.chunkEnd
      });
     // this.uploadChunk(entry);
    }
    return fileQueue;
  }


  public splitChunk(file: any, start: any,chunkSize:any) {
    const end = file.size;
    if (start >= end) {
      return {
        success: false,
        result: null
      };
    }
    const chunkStart = start;
    const chunkEnd = Math.min(start + chunkSize, end);

    return {
      success: true,
      chunkEnd,
      result: file.slice(chunkStart, chunkEnd)
    };
  }

  SortData(data:any[],param:any,operation:string)
  {
    if(operation==SortTypes.ascending)
    {
      return data.sort(function (a: any, b: any) {
        if (a[param] > b[param]) {
          return 1;
        }
        else if (a[param] < b[param]) {
          return -1;
        }
        return 0;
      });
    }
    else if(operation==SortTypes.descending)
    {
      return data.sort(function (a: any, b: any) {
        if (a[param] > b[param]) {
          return -1;
        }
        else if (a[param] < b[param]) {
          return 1;
        }
        return 0;
      });
    }
    return data;
  }

  getDeepCopy(data:any){
    if(data!=undefined)
      return JSON.parse(JSON.stringify(data));
    else
      return [];

  }

  formatstring(message:string,parmeter:any) {

    var args = parmeter;
    return message.replace(/{([0-9]+)}/g, function (match:any, index:any) {
      return typeof args[index] == 'undefined' ? match : args[index];
    });
  };


  Showfooter(visibility:boolean){
    const element = document.getElementById("mainfooter");
      if (element) {
      element.style.visibility = visibility==true?'visible':'hidden';
    }
  }

  Showheader(visibility:boolean){
    const element = document.getElementById("mainheader");
      if (element) {
      element.style.visibility = visibility==true?'visible':'hidden';
    }
  }

  

  MakeFooterRelative(makeRelative:boolean)
  {
    const element = document.getElementById("mainfooter");
      if (element) {
      element.style.position = makeRelative==true?'relative':'fixed';
    }
  }

}
