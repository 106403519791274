import OfflineMenu from '../../../../assets/OfflineMenu.json';
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { DropdownSubmenu } from '../../models/DropdownSubmenu';
import { HeaderOptions, MenuOptions } from '../../models/HeaderOptions';
import { HeaderService } from '../../services/header.service';
import { SessionService } from '../../services/session.service';
import { ChildActivationStart, Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import {AccountService} from '../../services/account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { constants } from '../../constants-enums/constants';
import { RecentMessage } from '../../models/user-notification.model';
import { AlertService } from '@app/shared/services/alert.service';
// import { SignalRService } from '../../services/signalR.service';
import { HelperService } from '@app/shared/services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerOptions: HeaderOptions[] = [];
  MVPEDIT:boolean=constants.ENABLEMVPITEMS;
  menuOptions: any = {};
  offlineMenu: any = {};
  isDataLoaded: boolean = false;
  username: any;
  loaded: boolean = true;
  firstlastname: any;
  accountname: any;
  isAuthenticated: boolean = false;
  menus: any;
  LoginStatus$ = new BehaviorSubject<boolean>(false);
  UserName$!: Observable<string>;
  isUserLoggedIn: boolean = false;
  selectedIndex: number = -1;
  @Input()hideheader!:boolean;
  RoleName:any;
  LoginStatus:any;
  InternalUser:boolean=true;
// bell
  UserNotificationCount!: number;
  UserNotification: RecentMessage[] = [];
//bell
  display: boolean = false;
  content!: string;
  isDataCountExist!: boolean;
  shortName: string="";
  firstName:string='';
   lastName:string=''; 


  constructor(private headerservice: HeaderService, private sessionService: SessionService, private router: Router, private oktaAuth: OktaAuth,
    private accountService: AccountService,private location:Location,private render:Renderer2,private alertService:AlertService,private helperservice:HelperService) {

  }

  
ngOnInit() {
if(this.location.path().includes('login/callback')){ 
  this.headerservice.showLoader();
  this.offlineMenu=[];
  localStorage.setItem("loginStatus", "0");
  this.helperservice.Showheader(true);
  this.helperservice.Showfooter(false);
}

  // this.accountService.isOfflineheader.subscribe(isOfflineMenu => {
  //   if (!isOfflineMenu) {
  //     this.isUserLoggedIn = true;
  //     this.offlineMenu = [];
  //     var checkMenus = this.sessionService.getLocalStorage('Menu');
  //     if (checkMenus != undefined || checkMenus != null) {
  //       this.offlineMenu = [];
  //       this.accountService.isUserLoggedIn.next(true);
  //     }
     
  //   }
  //   else {
     
  //     if(this.location.path()=="")
  //     this.selectedIndex=0;
  //       this.offlineMenu = OfflineMenu;
    
  //   }
  // })



  // if (!isOfflineMenu) {
  //   this.isUserLoggedIn = true;
  //   this.offlineMenu = [];
  //   var checkMenus = this.sessionService.getLocalStorage('Menu');
  //   if (checkMenus != undefined || checkMenus != null) {
  //     this.offlineMenu = [];
  //     this.accountService.isUserLoggedIn.next(true);
  //   }
   
  // }
  // else {
   
  //   if(this.location.path()=="")
  //   this.selectedIndex=0;
  //     this.offlineMenu = OfflineMenu;
  
  // }

  this.accountService.isUserLoggedIn.subscribe(value => {
    this.isUserLoggedIn = value;

    if (this.isUserLoggedIn) {
      this.offlineMenu=[]; 
      this.helperservice.Showheader(true);
      //this.hideheader=true;
      this.menus = this.sessionService.getLocalStorage('Menu');
      this.menuOptions = JSON.parse(this.menus);
      this.username = this.sessionService.getClaimFromToken("Username");
      this.firstlastname = this.sessionService.getClaimFromToken("FirstName") + ' ' + this.sessionService.getClaimFromToken("LastName");
      this.firstName =this.sessionService.getClaimFromToken("FirstName")!;
      this.lastName =this.sessionService.getClaimFromToken("LastName")!;
//      this.shortname(this.firstlastname);
      this.accountname = this.sessionService.getClaimFromToken("CompanyName");
      this.RoleName=this.sessionService.getClaimFromToken('Role');
      if(((this.RoleName).toLowerCase()).includes("external"))
      {
        this.InternalUser=false;
      }

      //SignalR

      // this.signalRService.messages$.subscribe((result: any) => {
        
      //   if (result != null && localStorage.getItem('user_id') == result.user_id)
      //     this.UserNotificationCount = result.unread_messages_cnt;
      // });
      // this.getNotificationCount();

      //SignalR

      //this.CheckUserRole();
    }
    else{
    if(this.location.path()=="")
    {
    this.selectedIndex=0;
    }

    if(!this.location.path().includes('login'))
     this.offlineMenu = OfflineMenu; 
    
    }
  });
  //alert('Logged-'+ this.isUserLoggedIn); 
  
}



 


  async login(path: string) {
    //alert('in login' + path);
    //this.selectedIndex=5;
    if (path === '/login') {

      await this.oktaAuth.signInWithRedirect({
        originalUri:'/auth',

      });
      //this.selectedIndex=0;  
    }
    else {
      this.router.navigateByUrl(path);
    }
  }

  FilterMenuType(){
    this.menuOptions[0].Menu.map(function (checkMenuType: any) {
      checkMenuType.SubMenu = checkMenuType.SubMenu.filter((x: { PermissionType: any; }) => x.PermissionType.toLowerCase() == 'menu');
      return checkMenuType;
    })
  }
 

  getHeaderData() {
    this.headerservice.getOptions().subscribe(
      response => {
        response.forEach(element => {
          let drop: DropdownSubmenu[] = [];
          if (element.submenu.length > 0) {
            for (var i = 0; i < element.submenu.length; i++) {
              drop.push(element.submenu[i]);
            }
          }
          let option = {} as HeaderOptions;
          option.optionName = element.optionName;
          option.optionType = element.optionType;
          option.path = element.path;
          option.iconLink = element.iconLink;
          if (drop.length != 0)
            option.submenu = drop;
          else
            option.submenu = [];
          this.headerOptions.push(option);
        });
      }
    )
  }

  CheckUserRole()
  {
    if (((this.RoleName).toLowerCase()).includes("internal")) {
      this.LoginStatus = JSON.parse(localStorage.loginStatus);
      if (this.LoginStatus == "0") {
        setTimeout(() => {
          if(this.checkroute('/userenquiry')){
          this.router.navigateByUrl('/userenquiry');
          }
          else if(this.checkroute('/company')){
            this.router.navigateByUrl('/company/internaluser');
          }
          else{
            this.router.navigateByUrl('/auth');
          }
          localStorage.setItem("loginStatus", "1");
        }, 0);
        
      }
    }
  }

  checkroute(route:string){
    let result=false;
    let checkValidRoute = this.sessionService.getLoggedInUserMenu().filter((a: { Path: string; }) => a.Path == route);
    if(checkValidRoute.length > 0)
    {
      result=true;
    }
    return result;
  }

 // notification

 getNotificationCount() {

  this.headerservice.getNotificationCount().subscribe((result) => {
  
    if (result != null) {      
      this.UserNotificationCount = result.UnreadMessageCount;
  }});

}

getUserNotification() {
  this.UserNotification = [];
  this.headerservice.getUserNotification().subscribe((result) => {
    if (result != null) {
      this.UserNotification = result;
    }
  });
}
 popup(data: any) {
  this.display = true;
  this.content = data.Body;
  var thisRef = this;
  this.headerservice
    .markasread(data.MessageId)
    .subscribe((result) => {
      if (result.data == null && result.message == null) {
        thisRef.getNotificationCount()
        //this.getNotificationCount();
      }
    });
}

//Notification

callback() {
  this.display = false;
}
  Logout() {
    this.accountService.logout();
  }
  async confirmlogout()
  {
    var message: string = "Do you want to logout from the application?";
    var header: string = "Logout";
    if (await this.alertService.confirmation(message, header))
     {
      this.Logout();
     }
  }
 

 

  ngOnDestroy() {

  }
  shortname(fullname:string)
  {
  
   this.shortName=fullname.split(' ').slice(0, 2).map(n => n[0].toUpperCase()).join('');
   
  
    
  }
}