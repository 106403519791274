import { environment } from "src/environments/environment";
export class constants {
    static TITLE = environment.Settings.WEB_TITLE;
    static VERSION = environment.Settings.VERSION;
    static LANGUAGE = environment.Settings.WEB_LANGUAGE;
    static BASE_API_ENDPOINT = environment.Settings.WEB_TARGET_API + "api/";
    static SITE_KEY = environment.Settings.SITE_KEY;
    static ACCORDION_COUNT = environment.Settings.accordion_count
    static OktaIssuer = environment.Settings.OktaIssuer;
    static OktaClientId = environment.Settings.OktaClientId;
    static OktaRedirectURI = environment.Settings.OktaRedirectURI;
    static MAX_FILE_SIZE = environment.Settings.MAX_UPLOAD_SIZE;
    static APPROVAL_STATUS = [{ name: 'Pending', value: 'Pending' }, { name: 'Approved', value: 'Approved' }, { name: 'Not Interested', value: 'NotInterested' }];
    static NOTE = "Note: Only csv files are allowed | Max allowed size is 350MB.";
    static FILESIZE = environment.Settings.FileSize;
    static WhitepapersStorageFolderName = environment.Settings.WhitepapersStorageFolderName;
    static ACCESSDENIEDNOTE = "You are Unauthorized to access the Discovery Application.";
    static DATAINGESTFILESTATUS = [
        { name: 'Yet to Validate', value: "Uploaded" },
        { name: 'Validating', value: "Validating,ValidationInprogress" },
        { name: 'Validated', value: "Validated" },
        { name: 'Failed', value: "Failed" },
        { name: 'ReUploaded', value: 'ReUploaded' },
        // { name: 'Imported', value: 'In Progress - Snowflake_Main' },
        { name: 'Completed', value: 'Completed' },
        { name: 'Deleted', value: 'To Be Deleted,Deleted' },

    ];
    static USERSTATUS = [{ name: 'All', value: "ALL" }, { name: 'Active', value: "true" }, { name: 'InActive', value: "false" }];
    static TABLEAUBASEURL = environment.Settings.TableauBaseUrl;
    static TABLEAUUSERNAME = environment.Settings.TableauUserName;
    static TABLEAUWORKBOOKANDVIEWPART = environment.Settings.TableauWorkBookAndViewPart;
    static AZURECONNECTION = environment.Settings.azureConnection;
    static IDLE_TIME = environment.Settings.IdleTime;
    static WARNING_TIME = environment.Settings.WarningTime;
    static TECH_SUPPORT_CONTACT = environment.Settings.techsupportcontact;
    static TECH_SUPPORT_MAIL = environment.Settings.techsupportmail;
    static SALES_SUPPORT_CONTACT = environment.Settings.salessupportcontact;
    static SALES_SUPPORT_MAIL = environment.Settings.salessupportmail;
    static STATUSINFO = [
        { name: "Yet To Validate", description: "File is newly uploaded for Data Ingest Process." },
        { name: "Validating", description: "Data Ingestion Validation started on Schema and Data level." },
        { name: "Validated", description: "File's Schema and Data Validations are passed with all cases." },
        { name: "Failed", description: "File has any Schema or Data validation errors." },
        { name: "ReUploaded", description: "TI team rectified errors and reuplod file for Data Ingest Process again." },
        // { name: "Imported", description: "File data is imported into Snowflake Tables." },
        { name: "Completed", description: "File data is imported into Snowflake Tables and data for building DataMarts." },
        { name: "Deleted", description: "File is deleted after Data Ingest process." }
    ];
    static ENABLEMVPITEMS = environment.Settings.EnableMVPItems;
    static FILENAMEREGEX = new RegExp("^[a-zA-Z0-9-@_(). ]+$");
    static UserView= [
        {
            "Role":"Internal Users",
            "IsVisible":false
            
        },
        {
            "Role":"External Users",
            "IsVisible":true
        }
    ];
    static COMPANY_ALREADY_MAPPED_NOTE = "This {0} is assigned to <b> {1} </b> account(s). Do you want to continue to change the access of assigned account(s)?";
    static FILE_SIZE_NOTE = "File size must be greater than {0} bytes.";


}

