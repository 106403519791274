import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { HelperService } from './helper.service';
import { APIRequest } from '../models/apirequest.model';
import { map, catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { of, throwError } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { constants } from '../constants-enums/constants';
import { AccountService } from './account.service';
export enum APIMethod {
  GET,
  POST,
  PUT,
  DELETE,
  POST_FILE,
}

/**
 * Service for manage API executions like GET, POST, PUT, DELETE, GET FILE USING POST METHOD.
 * [Injectable description] -> Annotation to indicate that service will be injectale in application.
 */

@Injectable()
export class ApiService {
  //variable declaration
  private baseURl: string = constants.BASE_API_ENDPOINT;
  headers: HttpHeaders;
  @BlockUI() public blockUI!: NgBlockUI;
  /**
   * Constructor for ApiService.
   * @param {Http} public apiExecuter [HTTP client to execute methods like GET, POST, PUT, DELETE]
   */
  constructor(
    public apiExecuter: HttpClient,
    public sessionService: SessionService,
    public helperService: HelperService,
    private accountService:AccountService
  ) {
    this.headers = new HttpHeaders();
  }

  BlockAllKeyStrokes()
  {
    window.addEventListener('keydown', this.triggerLog, true);
  }

  executeAPI(apiRequest: APIRequest, fileEvent?: boolean) {
    if ((!apiRequest.getCanHideSpinner()) && (fileEvent == false || fileEvent == undefined)) 
    {
      this.BlockAllKeyStrokes();
      this.blockUI.start();
    }
    this.populateHeaders(apiRequest.getContentType());
    switch (apiRequest.method) {
      case APIMethod.GET:
        return this.apiExecuter
          .get(this.baseURl + apiRequest.endpoint, { headers: this.headers })
          .pipe(
            map((res) => this.handleResponse(res)),
            catchError((data) => {
              window.removeEventListener('keydown', this.triggerLog, true);
              this.blockUI.stop();
              this.handleUnauthorizedError(data);
              return throwError(data);
            })
          );

      case APIMethod.POST:
        return this.apiExecuter
          .post(this.baseURl + apiRequest.endpoint, apiRequest.getRawBody(), {
            headers: this.headers,
          })
          .pipe(
            map((res) => this.handleResponse(res)),
            catchError((data) => {
              
              this.blockUI.stop();
              window.removeEventListener('keydown', this.triggerLog, true);
              if (data.error)
                this.helperService.showToaster(
                  data.error.status,
                  data.error.message
                );
              this.handleUnauthorizedError(data)
              return throwError(data);
            })
          );

      case APIMethod.PUT:
        return this.apiExecuter
          .put(this.baseURl + apiRequest.endpoint, apiRequest.getRawBody(), {
            headers: this.headers,
          })
          .pipe(
            map((res) => this.handleResponse(res)),
            catchError((data) => {
              if (data.error)
              this.helperService.showToaster(
                data.error.status,
                data.error.message
              );
              this.handleUnauthorizedError(data)
            return throwError(data);
            })
          );

      case APIMethod.DELETE:
        return this.apiExecuter
          .delete(this.baseURl + apiRequest.endpoint, { headers: this.headers })
          .pipe(
            map((res) => this.handleResponse(res)),
            catchError((data) => {
              this.blockUI.stop();
              window.removeEventListener('keydown', this.triggerLog, true);
              this.handleUnauthorizedError(data)
              return throwError(data);
            })
          );

      case APIMethod.POST_FILE:
        return this.apiExecuter
          .post(this.baseURl + apiRequest.endpoint, apiRequest.getRawBody(), {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
          })
          .pipe(
            map((res) => this.handleResponse(res)),
            catchError((data) => {
              this.blockUI.stop();
              window.removeEventListener('keydown', this.triggerLog, true);
              if (data.error)
                this.helperService.showToaster(
                  data.error.status,
                  data.error.message
                );
              this.handleUnauthorizedError(data);
              if(this.handleInterServerError(data))
              {
                return of({
                  internalServerError:true
                });
              }
              return throwError(data);
            })
          );
    }
  }

  populateHeaders(contentType: string) {
    this.headers.set('Content-Type', contentType);
  }
   GetCustomHeaders(chunkId: string): HttpHeaders {
    const token = this.sessionService.getAccessToken();
    let headers: HttpHeaders;
    headers = new HttpHeaders().set('Content-Type', 'application/octet-stream')
      .set('X-Chunk-Id', chunkId).set('Authorization',`bearer ${token}`);
    return headers;
  }
  handleResponse(res: any) {
    this.blockUI.stop();
    window.removeEventListener('keydown', this.triggerLog, true);
    const parseResponse = res;
    return res;
  }
  handleUnauthorizedError(err:any){
  if(err instanceof HttpErrorResponse && err.status === 403){
    this.accountService.isUnauthorized.next(true);
  }
  this.blockUI.stop();
  window.removeEventListener('keydown', this.triggerLog, true);
}
  handleInterServerError(data:any):boolean
  {
    if (data != null && data.status===500)
    {
      this.blockUI.stop();
      window.removeEventListener('keydown', this.triggerLog, true);
      return true;
    }
    this.blockUI.stop();
    window.removeEventListener('keydown', this.triggerLog, true);
    return false;
  }
  triggerLog(e: KeyboardEvent) {
      e.preventDefault();
      e.stopImmediatePropagation();
  }
}
