import { Injectable } from '@angular/core';
import {AuthService} from './shared/services/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService : AuthService, private router : Router) {}
  canActivate(): boolean {  
    if (!this.authService.gettoken()) {  
        this.router.navigateByUrl("/");  
    }  
    return this.authService.gettoken();  
}  
}
