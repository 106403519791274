import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionService } from './shared/services/session.service';
import { HelperService } from './shared/services/helper.service';
import { ApiService } from './shared/services/api.service';
import { AccountService} from './shared/services/account.service';

import { AuthGuard } from './auth-guard';
import { APIInterceptor } from './api-interceptor';
import { CookieService } from 'ngx-cookie-service';
import { BlockUIModule } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { OKTA_CONFIG, OktaAuthModule,OktaAuthGuard,OktaCallbackComponent } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HeaderComponent } from './shared/modules/header/header.component';
import { FooterComponent } from './shared/modules/footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AuthService } from './shared/services/auth.service';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { SignalRService } from './shared/services/signalR.service';
import {constants} from './shared/constants-enums/constants';
// import { FiltermenuPipe } from './shared/pipe/filtermenu.pipe';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { UserIdleModule } from 'angular-user-idle';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SpeedDialModule} from 'primeng/speeddial';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

const config = {
  issuer:constants.OktaIssuer,// 'https://dev-92837008.okta.com/oauth2/default',//'https://emtecincdiscovery.okta.com/oauth2/default',
  clientId: constants.OktaClientId,// '0oa2h041ffKsIcLM95d7',//0oa3s5adfnUeWiY3M696',
  redirectUri:constants.OktaRedirectURI,// window.location.origin + '/login/callback',
};
const oktaAuth = new OktaAuth(config);

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    OktaAuthModule,
    ToastModule,
    BsDropdownModule.forRoot(),
    RecaptchaFormsModule,
    RecaptchaModule,
    ButtonModule,
    DialogModule,TooltipModule,
    OverlayPanelModule,
    SpeedDialModule,
    // UserIdleModule.forRoot({idle:10, timeout:constants.WARNING_TIME}),
    ConfirmDialogModule,
   UserIdleModule.forRoot({idle:constants.IDLE_TIME, timeout:constants.WARNING_TIME}),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth },
    },
    AuthGuard,
    MessageService,
    HelperService,
    SessionService,
    ApiService,
    CookieService,
    AuthService,   
    AccountService,
    ConfirmationService,
    SignalRService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  
}
